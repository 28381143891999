import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

interface BannerCTAProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerCTA = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 40px;
  ${({ theme }) => theme.gradient.secondaryGradientLR};
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  @media screen and (max-width: 1199px) {
    & h2 {
      font-size: 40px;
      line-height: 50px;
    }
  }

  @media screen and (max-width: 400px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const BannerCTA: React.FC<BannerCTAProps> = ({ fields }) => (
  <StyledBannerCTA>
    <BannerWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 mb-4 mb-lg-0">
            <Content content={fields.description || '/'} />
          </div>
          <div className="col-lg-3 d-flex justify-content-start align-items-center">
            <Button className="primary filled" to={fields.link?.url || ''}>
              {fields.link?.title}
            </Button>
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerCTA>
)

export default BannerCTA
