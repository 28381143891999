import React from 'react'

// Third Party
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import styled from 'styled-components'

interface BreadCrumbProps {
  pageContext: any
  label?: string
  location: any
}

const StyledBreadcrumb = styled.div`
  & ol {
    display: flex;
    align-items: center;
    padding-left: 0;

    @media (min-width: 576px) {
      flex-direction: row;
    }

    @media (max-width: 575px) {
      /* flex-direction: column; */
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      width: 100%;
      margin-bottom: 0;
      margin-top: 2rem;

      .breadcrumb__separator {
        /* transform: rotate(90deg); */
      }
    }

    & li {
      list-style: none;

      & a {
        display: block;
        color: ${({ theme }) => theme.color.light};
        font-size: 14px;
        opacity: 0.3;
        transition: all 0.3s ease;

        &:first-letter {
          text-transform: uppercase;
        }

        &:hover {
          opacity: 1;
        }

        &[aria-current] {
          opacity: 1;
        }
      }
    }

    & span {
      margin: 0 10px;
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const BreadCrumb: React.FC<BreadCrumbProps> = ({ pageContext, label }) => {
  // const {
  //   breadcrumb: { crumbs },
  // } = pageContext

  const breads = pageContext

  if (breads && breads.breadcrumb) {
    breads.breadcrumb.crumbs.forEach((element: any) => {
      // eslint-disable-next-line no-param-reassign
      element.crumbLabel = element.crumbLabel.replace(/-/g, ' ')
    })
  }

  return (
    <StyledBreadcrumb>
      {breads && (
        <Breadcrumb
          crumbs={breads.breadcrumb.crumbs}
          crumbSeparator=" > "
          crumbLabel={label}
        />
      )}
    </StyledBreadcrumb>
  )
}

export default BreadCrumb
