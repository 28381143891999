/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

// Hooks
import useLayout from 'hooks/useLayout'

interface BannerPlatformProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerPlatform = styled.section``

const BannerWrapper = styled.div`
  padding: 160px 0 60px 0;
  position: relative;
  height: fit-content;
  ${({ theme }) => theme.gradient.primaryGradient};
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const StyledPlaatjie = styled(Plaatjie)`
  margin-top: -40px;
  top: -40px;
  @media (min-width: 992px) {
    width: 800px;
  }
  @media (max-width: 991px) {
    width: 600px;
  }
`

const RowButton = styled(Button)`
  & svg {
    height: 24px;
    width: 20px;
    margin-left: 20px;
    @media (max-width: 991px) {
      height: 19px;
      width: 15px;
    }
  }

  @media (min-width: 992px) {
    font-size: 25px;
  }
`

const StyledButton = styled(Button)`
  /* position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; */
`

const Row = styled.div`
  @media (min-width: 1200px) {
    padding: 0 60px;
  }
`

const GreySpace = styled.div`
  position: relative;
  margin: 0 0 60px 0;
  background-color: ${({ theme }) => theme.color.grey};
`

const BannerPlatform: React.FC<BannerPlatformProps> = ({ fields }) => {
  const layout = useLayout()

  return (
    <StyledBannerPlatform>
      <BannerWrapper>
        <div className="container pt-sm-5 pb-sm-5 pt-3 pb-5">
          <Content content={fields.description || '/'} />
          <Row className="d-flex flex-column flex-sm-row pt-4 justify-content-center">
            {fields.links?.map((l, index) => (
              <div key={index} className="text-center">
                <RowButton
                  className="secondary transparent hover-light"
                  arrow
                  to={l?.link?.url || '/'}
                >
                  {l?.link?.title}
                </RowButton>
              </div>
            ))}
          </Row>
        </div>
      </BannerWrapper>
      <GreySpace className="d-flex justify-content-center">
        <StyledPlaatjie image={fields.image} alt="mock-up" />
      </GreySpace>
      <div
        className="position-relative d-flex justify-content-center"
        style={{ marginTop: '-5rem' }}
      >
        <StyledButton
          className="secondary filled"
          onClick={
            fields.link?.url === '/the-platform/#get-demo'
              ? () => layout.setDemoModal(true)
              : () => {}
          }
          to={
            fields.link?.url === '/the-platform/#get-demo'
              ? ''
              : fields.link?.url || '/'
          }
        >
          {fields.link?.title}
        </StyledButton>
      </div>
    </StyledBannerPlatform>
  )
}

export default BannerPlatform
