import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import PostGrid from 'components/elements/Posts/PostGrid'
import ParseContent from 'components/shared/ParseContent'
import Filter from 'components/elements/Posts/Filter'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Third Party
import styled from 'styled-components'

interface BannerAcademyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerAcademy = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 250px 0 50px 0;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 991px) {
    padding: 140px 0 15px 0;
  }

  & h2 {
    font-size: 0 !important;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const BannerAcademy: React.FC<BannerAcademyProps> = ({ fields }) => {
  const { allWpPost, allWpCategory } =
    useStaticQuery<GatsbyTypes.academyQuery>(graphql`
      query academy {
        allWpPost(sort: { order: DESC, fields: date }) {
          edges {
            node {
              ...generalPostFragment
            }
          }
        }

        allWpCategory(filter: { databaseId: { ne: 1 } }) {
          edges {
            node {
              id
              name
              databaseId
            }
          }
        }
      }
    `)

  const posts: unknown = allWpPost.edges

  return (
    <StyledBannerAcademy>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="academy"
      >
        <BannerWrapper>
          <div className="container">
            <Content content={fields.description || '/'} />
            <h2>Blog</h2>
            <Filter />
          </div>
        </BannerWrapper>
        <div className="py-lg-5">
          {/* @ts-ignore */}
          <PostGrid fields={fields} />
        </div>
      </BlogBink>
    </StyledBannerAcademy>
  )
}

export default BannerAcademy
