/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled from 'styled-components'

interface BannerBuildProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerBuild = styled.section``

const BannerWrapper = styled.div`
  padding: 160px 0 0px 0;
  position: relative;
  height: fit-content;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 991px) {
    padding: 140px 0 15px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 100px 0 0 0;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  @media screen and (max-width: 1199px) {
    & h1,
    h2 {
      font-size: 50px;
      line-height: 60px;
    }
  }

  @media screen and (max-width: 991px) {
    & h1,
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const RowButton = styled(Button)`
  font-size: 25px;

  & svg {
    height: 24px;
    width: 20px;
    margin-left: 20px;
  }

  @media screen and (max-width: 991px) {
    padding: 5px 40px;
  }
`

const Row = styled.div`
  padding: 0 60px;

  @media screen and (max-width: 1199px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`

const BannerBuild: React.FC<BannerBuildProps> = ({ fields }) => (
  <StyledBannerBuild>
    <BannerWrapper>
      <div className="container pt-5">
        <div className="d-flex flex-column flex-sm-row">
          <Content content={fields.description || '/'} />
          <Row className="d-flex flex-column flex-md-row pt-xl-4 pt-lg-2 justify-content-center">
            {fields.links?.map((l, index) => (
              <div key={index} className="text-center">
                <RowButton
                  className="secondary transparent hover-light"
                  arrow
                  to={l?.link?.url || '/'}
                >
                  {l?.link?.title}
                </RowButton>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerBuild>
)

export default BannerBuild
