import React from 'react'

// Components
import { useBlogBink, BlogBinkFilter } from '@ubo/blog-bink'

// Images
import flowizeicon from 'img/flowizeicon.svg'

// Third Party
import styled from 'styled-components'

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border-width: 2px;
  border-style: solid;
  border-radius: 25.5px;
  font-size: 16px;
  padding: 6px 25px;
  min-width: 160px;
  margin: 0 15px;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  transition: all 0.3s ease;

  color: ${({ theme, selected }) =>
    selected ? theme.color.secondary : theme.color.light};

  border-color: ${({ theme, selected }) =>
    selected ? theme.color.secondary : theme.color.light};

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: 1199px) {
    margin: 5px 10px;
  }

  @media screen and (max-width: 400px) {
    padding: 6px 10px;
    min-width: 120px;
  }
`

interface FilterProps {}

interface SingleFilterProps {
  node: {
    id: string
    name: string
  }
}

const FilterWrapper = styled.div`
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 20px;
`

const Flowizeicon = styled.img`
  position: absolute;
  right: 8px;
`

const Filter: React.FC<FilterProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <FilterWrapper className="pt-4 pb-2 d-flex justify-content-center">
        Filter results
      </FilterWrapper>
      <div className="d-flex flex-wrap justify-content-center">
        {blogBink.categories.map((category: SingleFilterProps) => (
          <StyledBlogFilter
            key={category.node.id}
            id={category.node.id}
            selected={blogBink.isSelected(category.node.id)}
            className="mr-3 position-relative"
          >
            {category.node.name}
            {blogBink.isSelected(category.node.id) && (
              <Flowizeicon src={flowizeicon} />
            )}
          </StyledBlogFilter>
        ))}
      </div>
    </div>
  )
}

export default Filter
