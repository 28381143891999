import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

import FlowizeBlocks from 'img/big_flowize_blocks.inline.svg'

interface BannerTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerText = styled.section``

const BannerWrapper = styled.div<{ background: any }>`
  padding: 30px 0 20px 0;
  position: relative;
  height: fit-content;
  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      ${theme.gradient.primaryGradientLR};
    `}
  ${({ background, theme }) =>
    background === 'secondary' &&
    css`
      ${theme.gradient.secondaryGradientLR};
    `}

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }
`

const Content = styled(ParseContent)<{ svg: boolean }>`
  position: relative;
  z-index: 3;
  color: ${(props) => props.theme.color.light};

  ${(props) =>
    props.svg &&
    css`
      font-size: 22px;
    `};

  @media screen and (max-width: 1199px) {
    & h2 {
      font-size: 40px;
      line-height: 50px;
    }
  }

  @media screen and (max-width: 400px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }

  & h3,
  & h4,
  & h5,
  & h6 {
    padding-bottom: 10px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const BackgroundSVG = styled.div`
  & svg {
    position: absolute;
    bottom: 0;
    right: 0;
    height: auto;
    width: 70%;
    z-index: 1;
  }
`

const BannerText: React.FC<BannerTextProps> = ({ fields }) => (
  <StyledBannerText className="overflow-hidden position-relative">
    {fields.backgroundsvg && (
      <BackgroundSVG>
        <FlowizeBlocks />
      </BackgroundSVG>
    )}

    <BannerWrapper background={fields.background}>
      <div className="container py-lg-5 py-3">
        <div className="row justify-content-center">
          <div className={!fields.backgroundsvg ? 'col-lg-6' : 'col-lg-8'}>
            <Content
              content={fields.description || '/'}
              svg={fields.backgroundsvg}
            />
          </div>
          {fields.seconddescription && (
            <div className="col-lg-6">
              <Content content={fields.seconddescription || ''} />
            </div>
          )}
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerText>
)

export default BannerText
