import React from 'react'
import styled from 'styled-components'

// Elements
import BreadCrumb from 'components/elements/BreadCrumb'
import Share from 'components/elements/Share'

interface BannerShareProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerShare = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 200px 0 100px 0;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 1199px) {
    padding: 200px 0 30px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 140px 0 15px 0;
  }
`

const StyledShare = styled(Share)`
  position: relative;
  z-index: 9;
  & h2 {
    color: #fff !important;
  }

  & svg path {
    fill: #fff !important;
  }
`

const BannerShare: React.FC<BannerShareProps> = ({ pageContext, location }) => (
  <StyledBannerShare>
    <BannerWrapper>
      <div className="container">
        <BreadCrumb location={location} pageContext={pageContext} />

        <div className="row justify-content-center">
          <div className="col-lg-10">
            <StyledShare heading="Share" body="" title="" location={location} />
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerShare>
)

export default BannerShare
