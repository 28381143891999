import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BreadCrumb from 'components/elements/BreadCrumb'

interface BannerDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerDetail = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 200px 0 100px 0;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 1199px) {
    padding: 200px 0 30px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 140px 0 15px 0;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const BannerDetail: React.FC<BannerDetailProps> = ({
  fields,
  pageContext,
  location,
}) => (
  <StyledBannerDetail>
    <BannerWrapper>
      <div className="container">
        <BreadCrumb location={location} pageContext={pageContext} />
        <Content content={fields.description || '/'} />
      </div>
    </BannerWrapper>
  </StyledBannerDetail>
)

export default BannerDetail
