import React from 'react'

// Components
import Button from 'components/elements/Buttons/Button'
import Post from 'components/elements/Posts/Post'
import { useBlogBink, BlogBinkButton } from '@ubo/blog-bink'

// Third Party
import styled from 'styled-components'

interface PostGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const NoResult = styled.div`
  font-size: 50px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const StyledButton = styled(Button)`
  & .is-custom {
    padding: 0;
  }

  & button {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }
`

const PostGrid: React.FC<PostGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container pb-lg-5 pb-3">
      <div className="row">
        {blogBink.posts.length >= 1 ? (
          <>
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <div
                  key={post.node.id}
                  className="col-lg-4 col-md-6 mt-lg-5 mt-3"
                >
                  <Post node={node} blogFields={fields} />
                </div>
              )
            })}
          </>
        ) : (
          <NoResult className="text-center">
            Oops, It seems there are no results!
          </NoResult>
        )}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-lg-5 mt-3 text-center">
          <StyledButton className="secondary filled" isCustom>
            <BlogBinkButton>
              {fields.loadmoretext || `Show more`}
            </BlogBinkButton>
          </StyledButton>
        </div>
      )}
    </div>
  )
}

export default PostGrid
