import React from 'react'
import styled from 'styled-components'

import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  padding: 200px 0 100px 0;
  position: relative;
  height: fit-content;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 1199px) {
    padding: 150px 0 15px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 140px 0 15px 0;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1 {
    font-size: 50px;
    line-height: 60px;
    padding-bottom: 20px;
  }

  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }

  @media screen and (max-width: 1199px) {
    & button {
      margin: 5px 0;
    }

    & h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }

  @media screen and (max-width: 767px) {
    & ul {
      padding-left: 1.5rem !important;
      li {
        font-size: 14px !important;
        line-height: 24px !important;
      }
    }
    & button {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 575px) {
    margin-top: 2rem;
    & ul {
      li {
        &:before {
          top: 6px !important;
        }
      }
    }

    & button {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 400px) {
    & button {
      margin: -10px 0 30px 0;
    }
  }
`

const AnimationPlaceholder = styled.div`
  color: ${({ theme }) => theme.color.light};
  height: 90%;
  width: 100%;
  min-height: 250px;
`

const Image = styled(Plaatjie)`
  z-index: 4;
  width: 100%;
  height: 100%;
  & img {
    object-fit: contain !important;
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => (
  <StyledBannerHome>
    <BannerWrapper>
      <div className="container py-lg-5 py-3">
        <div className="row">
          <div className="col-lg-6">
            <Content content={fields.description || ''} />
          </div>
          <div className="col-lg-6 pt-lg-0 pt-4">
            <div className="d-flex align-items-center h-100">
              <AnimationPlaceholder className="position-relative d-flex justify-content-center align-items-center">
                <Image image={fields.animation} alt="" loading="eager" />
              </AnimationPlaceholder>
            </div>
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerHome>
)

export default BannerHome
