import React from 'react'
import styled from 'styled-components'

// Images
import BlueFlowizeBlocks from 'img/blue_flowize_blocks.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerWorldwideProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerWorldwide = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 200px 0 0 0;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 991px) {
    padding: 160px 0 15px 0;
  }

  @media screen and (max-width: 767px) {
    padding: 160px 0 0 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
  width: 100%;

  & img {
    object-fit: contain !important;
  }

  @media screen and (min-width: 992px) {
    min-height: 580px;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  @media screen and (max-width: 400px) {
    & h1 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const ContentContainer = styled.div`
  margin-top: -100px;
  margin-bottom: 15px;
  z-index: 1;
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 45px;
  @media (min-width: 992px) {
    padding: 40px 80px;
  }
  @media (max-width: 991px) {
    padding: 20px;
    margin-top: -30px;
  }

  @media screen and (max-width: 575px) {
    padding: 15px;
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
  pointer-events: none;
`

const BannerWorldwide: React.FC<BannerWorldwideProps> = ({ fields }) => (
  <StyledBannerWorldwide>
    <BannerWrapper>
      <div className="container">
        <Content content={fields.title || '/'} />
      </div>
    </BannerWrapper>
    <div className="position-relative">
      {/* <SvgWrapper>
        <BlueFlowizeBlocks />
      </SvgWrapper> */}
      <StyledPlaatjie image={fields.image} alt="" />
    </div>
    <div className="container pb-lg-5 pb-3">
      <div className="row justify-content-center">
        <div className="col-xl-9 col-lg-11">
          <ContentContainer>
            <ParseContent content={fields.description || '/'} />
          </ContentContainer>
        </div>
      </div>
    </div>
  </StyledBannerWorldwide>
)

export default BannerWorldwide
