import React from 'react'
import { navigate } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonCSS } from 'components/elements/Buttons/Button'

// Components
import FormDuuf from 'components/related/Form'

// Images
import Close from 'img/close.inline.svg'

// Third Party
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Hooks
// import useLayout from 'hooks/useLayout'

interface BannerEBookProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerEBook = styled.section`
  padding: 100px 0 100px 0;

  @media screen and (max-width: 991px) {
    padding: 40px 0 150px 0;
  }
`

const BannerWrapper = styled.div`
  position: relative;
  height: 425px;
  ${({ theme }) => theme.gradient.primaryGradientLR};

  @media screen and (max-width: 991px) {
    height: fit-content;
  }
`

const StyledPlaatjie = styled(Plaatjie)``

const ImageContainer = styled.div`
  position: absolute;
  right: -150px;

  @media (min-width: 1200px) {
    width: 930px;
    height: 625px;
    top: -150px;
  }

  @media (max-width: 1199px) {
    width: 730px;
    height: 425px;
    top: 0;
  }

  @media (max-width: 991px) {
    width: 330px;
    height: 125px;
    top: 200px;
    right: -70px;
  }

  @media (max-width: 767px) {
    width: 430px;
    height: 325px;
    top: 200px;
    right: -180px;
  }

  @media (max-width: 575px) {
    width: 338px;
    height: 99px;
    top: 284px;
    right: -132px;
  }

  @media screen and (max-width: 400px) {
    width: 338px;
    height: 99px;
    top: 284px;
    right: -132px;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h2 {
    padding-bottom: 20px;
    @media (min-width: 992px) {
      font-size: 30px;
      line-height: 31.2px;
    }
  }

  & h3,
  & h4,
  & h5 {
    margin-top: 10px;
    margin-bottom: 12px;
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: 24.52px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.small};
      line-height: ${({ theme }) => theme.font.size.small};
    }
  }
`

const ModalContainer = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
  cursor: pointer;

  @media screen and (max-width: 575px) {
    padding: 15px;
  }
`

const Modal = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 40px;
`

const Header = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.dark};
  padding: 30px 100px;
  text-align: center;
  font-size: 34px;
  line-height: 40px;
  border-radius: 40px 40px 0 0;
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
  position: relative;

  @media screen and (max-width: 575px) {
    padding: 20px 25px 15px 25px;
  }

  @media screen and (max-width: 350px) {
    font-size: 24px;
    line-height: 30px;
    padding: 15px 35px 15px 35px;
  }
`

const ModalContent = styled.div`
  padding: 30px 100px 30px 100px;

  @media screen and (max-width: 575px) {
    padding: 15px;
  }
`

const CloseWrapper = styled.button`
  position: absolute;
  right: 19px;
  top: 14px;

  & svg {
    width: 25px;
    height: 25px;
    opacity: 0.65;

    & path {
      fill: ${({ theme }) => theme.color.dark};
    }

    &:hover {
      opacity: 1;
    }
  }
`

const BannerEBook: React.FC<BannerEBookProps> = ({ fields, location }) => {
  const [open, setModal] = React.useState(false)
  // const layout = useLayout()

  React.useEffect(() => {
    if (location.hash === '#download-brochure') {
      setModal(true)
    }
  }, [])

  // React.useEffect(() => {
  //   if (open) {
  //     layout.setIsScrollable(false)
  //   } else {
  //     layout.setIsScrollable(true)
  //   }
  // })

  const close = () => {
    setModal(false)
    // eslint-disable-next-line no-param-reassign
    navigate('#flowize-ebook')
  }

  return (
    <StyledBannerEBook id="#flowize-ebook">
      <BannerWrapper>
        <div className="container h-100 py-5 position-relative">
          <div className="row h-100">
            <div className="col-lg-6 d-flex align-items-center">
              <Content content={fields.description || '/'} />
            </div>
            <div className="col-lg-6">
              <ImageContainer>
                <StyledPlaatjie image={fields.image} alt="" />
              </ImageContainer>
            </div>
          </div>
        </div>
      </BannerWrapper>
      <AnimatePresence>
        {open && (
          <ModalContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => close()}
          >
            <Modal onClick={(e) => e.stopPropagation()}>
              <Header>
                Download our brochure now!
                <CloseWrapper
                  className="position-absolute"
                  onClick={() => close()}
                >
                  <Close />
                </CloseWrapper>
              </Header>
              <ModalContent>
                <StyledFormDuuf id={4} generate />
              </ModalContent>
            </Modal>
          </ModalContainer>
        )}
      </AnimatePresence>
    </StyledBannerEBook>
  )
}

export default BannerEBook

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1rem;

    & .form-duuf-error {
      padding-left: 20px;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #e5e5e5;
    border-radius: 25.5px;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    padding: 0 20px;
    font-size: 16px;
  }

  & .form-duuf-textarea {
    height: 143px;
    padding-top: 10px;
  }

  & .form-duuf-file-button {
    ${ButtonCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    padding: 0 0 5px 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 14px;
  }

  & .form-duuf-submit {
    ${ButtonCSS};

    border: none;
    padding: 15px 0;

    a,
    button {
      background-color: ${({ theme }) => theme.color.secondary};
      border-color: ${({ theme }) => theme.color.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      border-radius: 25.5px;
      height: 100%;
      color: ${({ theme }) => theme.color.primary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      font-size: 14px;
      padding: 15px 80px;
    }

    svg {
      color: ${({ theme }) => theme.color.primary};
    }

    &-disabled {
      opacity: 1;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
    padding: 0 0 20px 0;
  }

  & .form-duuf-something-wrong {
    color: red;
    font-size: 25px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`
