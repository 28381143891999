import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BreadCrumb from 'components/elements/BreadCrumb'
import Plaatjie from '@ubo/plaatjie'

interface BannerPartnerProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerPartner = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 200px 0 0 0;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 991px) {
    padding: 140px 0 15px 0;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
  width: 100%;

  & img {
    object-fit: contain !important;
  }
`

const BannerPartner: React.FC<BannerPartnerProps> = ({
  fields,
  pageContext,
  location,
}) => (
  <StyledBannerPartner>
    <BannerWrapper>
      <div className="container">
        <BreadCrumb location={location} pageContext={pageContext} />
        <Content content={fields.title || '/'} />
      </div>
    </BannerWrapper>
    <ContentWrapper className="py-lg-5 py-3">
      <div className="container py-lg-4">
        <div className="row justify-content-evenly">
          <div className="col-lg-5">
            <StyledPlaatjie image={fields.image} alt="" loading="eager" />
          </div>
          <div className="col-lg-6 pt-lg-0 pt-3">
            <div className="d-flex h-100 align-items-center">
              <ParseContent content={fields.description || ''} />
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </StyledBannerPartner>
)

export default BannerPartner
