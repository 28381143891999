import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import { ButtonCSS } from 'components/elements/Buttons/Button'

interface BannerDownloadProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerContact = styled.section`
  margin: 0 0 0 0;
`

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 250px 0 200px 0;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 991px) {
    padding: 160px 0 200px 0;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 45px;
  margin-top: -180px;
  position: relative;
  z-index: 3;

  @media (min-width: 992px) {
    padding: 80px 100px;
  }
  @media (max-width: 991px) {
    padding: 30px 30px;
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;

    & .form-duuf-error {
      padding-left: 20px;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #e5e5e5;
    border-radius: 25.5px;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    padding: 0 20px;
    font-size: 16px;
  }

  & .form-duuf-textarea {
    height: 143px;
    padding-top: 10px;
  }

  & .form-duuf-file-button {
    ${ButtonCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    padding: 0 0 5px 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 14px;
  }

  & .form-duuf-submit {
    ${ButtonCSS};

    border: none;

    a,
    button {
      background-color: ${({ theme }) => theme.color.secondary};
      border-color: ${({ theme }) => theme.color.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      border-radius: 25.5px;
      height: 100%;
      color: ${({ theme }) => theme.color.primary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      font-size: 14px;
      padding: 15px 80px;
    }

    svg {
      color: ${({ theme }) => theme.color.primary};
    }

    &-disabled {
      opacity: 1;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }

  & .form-duuf-something-wrong {
    color: red;
    font-size: 25px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const BannerDownload: React.FC<BannerDownloadProps> = ({ fields }) => (
  <StyledBannerContact>
    <BannerWrapper>
      <div className="container">
        <Content content={fields.description || '/'} />
      </div>
    </BannerWrapper>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <Block>
            <StyledFormDuuf id={6} generate={false}>
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <FormDuufGroup showIds={[4,1]} />
                    </div>
                    <div className="col-lg-6">
                      <FormDuufGroup showIds={[8]} />
                      <div className="d-flex justify-content-end">
                        <FormDuufSubmit />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StyledFormDuuf>
          </Block>
        </div>
      </div>
    </div>
  </StyledBannerContact>
)

export default BannerDownload
