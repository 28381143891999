import React from 'react'

// Compenents
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface PostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const PostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;

  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: 30.65px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.regular};
      line-height: ${({ theme }) => theme.font.size.regular};
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 991px) {
    margin-bottom: 4rem;
  }
`

const StyledPost = styled.div`
  position: relative;
  @media (min-width: 992px) {
    height: 540px;
  }
  @media (max-width: 991px) {
    height: 340px;
  }
`

const Gradient = styled.div`
  ${({ theme }) => theme.gradient.opaqueGradient};
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
`

const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 5;
  @media (min-width: 992px) {
    padding: 50px 40px;
  }
  @media (max-width: 991px) {
    padding: 10px 20px;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

const DemoSticker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.secondary};
  color: ${({ theme }) => theme.color.light};
  padding: 10px 30px;
  z-index: 2;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const Post: React.FC<PostProps> = ({ node, blogFields }) => {
  const isDemo =
    (node.categories?.nodes?.filter((n: any) => n.id === 'dGVybToxMjE=')
      .length || 0) >= 1
    
  const isDownload = 
  (node.categories?.nodes?.filter((n: any) => n.id === 'dGVybToxMzA=')
      .length || 0) >= 1

  const isTechTalk = 
  (node.categories?.nodes?.filter((n: any) => n.id === 'dGVybToxMjc=')
      .length || 0) >= 1

  return (
    <StyledPost>
      {isDemo && <DemoSticker>Demo</DemoSticker>}
      {isTechTalk && <DemoSticker>Techtalk</DemoSticker>}
      <Gradient />
      <StyledPlaatjie image={node.recap?.image} alt="" />
      <ContentContainer>
        <PostExcerpt content={node.recap?.excerpt || ''} />
        <StyledButton
          className="secondary hover-light transparent"
          arrow
          to={node.uri}
        >
          {blogFields.readmoretext || `${isDemo ? `Watch demo` : isDownload ? `Download` : isTechTalk ? 'Watch Techtalk' : `Read blog`}`}
        </StyledButton>
      </ContentContainer>
    </StyledPost>
  )
}

export default Post
