import React from 'react'
import { Link } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import BreadCrumb from 'components/elements/BreadCrumb'

// Third Party
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'

interface BannerPlatformDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
  location?: any
}

const StyledBannerPlatformDetail = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 180px 0 0 0;
  ${({ theme }) => theme.gradient.primaryGradient};

  @media screen and (max-width: 991px) {
    padding: 140px 0 0 0;
  }

  @media screen and (max-width: 575px) {
    padding: 120px 0 0 0;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const ExtraContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  margin: 0 0 100px 0;

  @media screen and (max-width: 991px) {
    margin: 0;
  }

  @media screen and (max-width: 575px) {
    & h1 {
      font-size: 20px !important;
    }
  }
`

const IconContainer = styled.div`
  padding: 10px 10px 15px 10px;

  & img {
    width: 60px;
    height: 60px;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.light};
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  opacity: 0.3;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.color.light};
    opacity: 1;
  }

  @media screen and (max-width: 991px) {
    font-size: 28px;
  }

  @media screen and (max-width: 575px) {
    font-size: 20px;
  }
`

const FlexContainer = styled.div`
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 575px) {
    & > div {
      flex-direction: column;
      align-items: center !important;
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
  width: 80%;

  & img {
    object-fit: contain !important;
  }

  @media (min-width: 992px) {
    min-height: 740px;
  }
`

const BannerPlatformDetail: React.FC<BannerPlatformDetailProps> = ({
  fields,
  pageContext,
  location,
}) => (
  <StyledBannerPlatformDetail>
    <BannerWrapper>
      <div className="container pt-lg-5 pt-3">
        <BreadCrumb location={location} pageContext={pageContext} />
        <FlexContainer className="d-flex">
          <div className="d-flex justify-content-between w-100 align-items-end pb-lg-2 pb-2 pt-lg-0 pt-3">
            {['Automate', 'Integrate', 'Communicate', 'Develop'].map(
              (i: string) => {
                if (fields.links?.find((e) => e?.link?.title === i)) {
                  return (
                    <StyledLink
                      className="pb-lg-2"
                      to={
                        fields.links.filter((e) => e?.link?.title === i)[0]
                          ?.link?.url || '/'
                      }
                    >
                      {
                        fields.links.filter((e) => e?.link?.title === i)[0]
                          ?.link?.title
                      }
                    </StyledLink>
                  )
                }
                return (
                  <div className="d-flex me-lg-5 me-3 align-items-lg-end align-items-center">
                    <IconContainer className="me-lg-3 me-3">
                      <img src={fields.icon?.localFile?.publicURL} alt="" />
                    </IconContainer>
                    <Content content={fields.title || '/'} />
                  </div>
                )
              }
            )}

            {/* {fields.links?.map((l) => (
              <StyledLink to={l?.link?.url || '/'}>{l?.link?.title}</StyledLink>
            ))} */}
          </div>
        </FlexContainer>
      </div>
    </BannerWrapper>
    <ExtraContentWrapper>
      <div className="container py-lg-5 py-3">
        <div className="row">
          <div className="col-lg-7">
            <StyledPlaatjie image={fields.image} alt="" />
          </div>
          <div className="col-lg-5 pt-lg-0 pt-3 d-flex align-items-center">
            <div className="ps-4">
              <ParseContent content={fields.description || ''} />
            </div>
          </div>
        </div>
      </div>
    </ExtraContentWrapper>
  </StyledBannerPlatformDetail>
)

export default BannerPlatformDetail
