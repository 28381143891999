import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerHome from 'components/flex/Banner/BannerHome'
import BannerEBook from 'components/flex/Banner/BannerEBook'
import BannerPlatform from 'components/flex/Banner/BannerPlatform'
import BannerText from 'components/flex/Banner/BannerText'
import BannerPlatformDetail from 'components/flex/Banner/BannerPlatformDetail'
import BannerBuild from 'components/flex/Banner/BannerBuild'
import BannerCTA from 'components/flex/Banner/BannerCTA'
import BannerDetail from 'components/flex/Banner/BannerDetail'
import BannerWorldwide from 'components/flex/Banner/BannerWorldwide'
import BannerAcademy from 'components/flex/Banner/BannerAcademy'
import BannerContact from 'components/flex/Banner/BannerContact'
import BannerPartner from 'components/flex/Banner/BannerPartner'
import BannerShare from 'components/flex/Banner/BannerShare'
import BannerDownload from 'components/flex/Banner/BannerDownload'
import BannerWhitepaper from './BannerWhitepaper'
import BannerBrochure from './BannerBrochure'

interface BannerShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
  pageContext?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({
  fields,
  pageContext,
  location = {},
}) => {
  const banners: BannersProps = {
    default: BannerDefault,
    detail: BannerDetail,
    home: BannerHome,
    partner: BannerPartner,
    ebook: BannerEBook,
    platform: BannerPlatform,
    platformdetail: BannerPlatformDetail,
    buildwithflowize: BannerBuild,
    academy: BannerAcademy,
    text: BannerText,
    cta: BannerCTA,
    worldwide: BannerWorldwide,
    contact: BannerContact,
    share: BannerShare,
    download: BannerDownload,
    whitepaper: BannerWhitepaper,
    brochure: BannerBrochure,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default BannerShell
